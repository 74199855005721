import { render, staticRenderFns } from "./AudioPageAll.vue?vue&type=template&id=48582f61&scoped=true&"
import script from "./AudioPageAll.vue?vue&type=script&lang=js&"
export * from "./AudioPageAll.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48582f61",
  null
  
)

export default component.exports